.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 3cm;
  /*Especificaelanchodelenlaceencm*/
  background-color: #e89ec4;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

}


.d-fluency-whatsapp-logo {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  /*Esta propiedad nos permite mover el logo o margen hacia la izquierda*/



}


.grid-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  /* Centra los botones horizontalmente */
  gap: 5px;
  /* Reduce el espacio entre los botones */
}

.Micontenedor {
  display: grid;

  justify-content: center;
  /* Centra los botones horizontalmente */



}