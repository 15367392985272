/* src/components/FacebookButton.css */
.WhatsappButtonFlowers-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 7cm; /* Especifica el ancho del enlace en cm */
    background-color: #e89ec4;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    
  }
  
  
  .fluency-whatsapp-logo2{
    width: 35px;
    height: 35px;
    margin-right: 10px; /*Esta propiedad nos permite mover el logo o margen hacia la izquierda*/ 
 
  
    
  }
.containergrupo
{
      display: grid;
  
      justify-content: center; /* Centra los botones horizontalmente */
     


}

.background-image {
  background-image: url('../assets/Productos.jfif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-primary{

  color:#051d4f
}

.FlowersNumber1
{
  margin-right: 620px; 


}

.FlowersNumber2
{
  margin-left: 620px; 

}

.FlowersNumber3
{
  margin-left: 620px; 

}

.FlowersNumber4
{
  margin-left: 620px; 

}


.FlowersNumber5
{
  margin-left: 620px; 

}


.FlowersNumber6
{
  margin-left: 620px; 

}


.FlowersNumber7
{
  margin-left: 620px; 

}


.FlowersNumber8
{
  margin-left: 620px; 

}



.FlowersNumber9
{
  margin-left: 620px; 

}



.FlowersNumber10
{
  margin-left: 620px; 

}

.FlowersNumber11
{
  margin-left: 620px; 

}

.FlowersNumber12
{
  margin-left: 620px; 

}


.FlowersNumber13
{
  margin-left: 620px; 

}


.FlowersNumber14
{
  margin-left: 620px; 

}


.FlowersNumber15
{
  margin-left: 620px; 

}


.FlowersNumber16
{
  margin-left: 620px; 

}



