/* src/components/FacebookButton.css */
.facebook-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 2cm; /* Especifica el ancho del enlace en cm */
    background-color: #3b5998;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    

   
  
    
  }
  
  
  .icons8-facebook-nuevo-48{
    width: 30px;
    height: 30px;
  
    
  }

  